@import "../../../styles-variables.scss";
.auto-complete-field {
    position: relative;
    bottom: -4px;
    margin-bottom: 8px;

    .mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
        padding-bottom: 2px;
        padding-top: 0px;
        padding-right: 0px;
        border-top: 0px;
    }
    .mat-mdc-form-field-wrapper {
        padding-bottom: 0px;
    }
    .mat-mdc-form-field-underline {
        position: absolut;
        bottom: 0;
    }
    .mat-mdc-form-field {
        width: 120px;
    }
}
