@use "@angular/material" as mat;
@mixin debug-border {
}
@mixin debug-borderX {
    border: 1px dotted black;
}
@mixin nav-bar-margin {
    margin-top: 50px;
}
@mixin max-form-width {
    max-width: 500px;
}
@mixin nav-bar-padding {
    padding-top: 50px;
}
@mixin whoo-background-cover {
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
@mixin whoo-box {
    border: 1px solid $color-mat-table-border;
    border-radius: 4px;
    @include mat.elevation(1);
}
@mixin whoo-background-box-white {
    background-color: rgba(255, 255, 255, 0.8);
    @include mat.elevation(2);
    padding: 10px;
    border-radius: 6px;
}
@mixin big-screen {
    @media (min-width: 675px) {
        @content;
    }
}
@mixin long-screen {
    @media (min-height: 2000px) {
        @content;
    }
}

@mixin below-medium-screen {
    @media (max-width: 950px) {
        @content;
    }
}
@mixin above-medium-screen {
    @media (min-width: 950px) {
        @content;
    }
}
@mixin small-screen {
    @media (max-width: 674px) {
        @content;
    }
}

$black: #32485d;
$color-input: #000000de;

$primary-color: #490b3d;
$primary-color-light: rgb(155, 89, 142);
$font: $primary-color;
$turkis: $primary-color;

$accent-color: #bd1e51;
$accent-color-light: #de8fa8;
$color-transparent-accent: #bd1e5017;

$warn-color: #f1b814;
$warn-color-light: #f1ba1462;
$color-transparent-warn: #f1ba1462;
$color-transparent-accent: #bd1e5017;
$color-transparent-primary: #490b3dc8;
$color-transparent-white: rgba(255, 255, 255, 0.661);

$color-success-background: #c2e4b4;
$color-info-background: #b4dee4;
$color-warn-background: #f1ba1436;
$color-error-background: #f3b4c8;
$color-mat-app-background: #fafafa;
$white: white;
$turkis-light: #fff0e4;

$mat-app-background-color: #fafafa;
$mat-app-background-color-transparent: #fafafadc;

$border-lighter2: #f7f0f7;
$border-lighter: #ede7ed;
$border-light: #c8b6c5;
$color-image-border: rgba(0, 0, 0, 0.12);

$color-background-lighter2: #f7f0f7;
$color-background-lighter: #f1ecf1;
$color-background-light: #c8b6c5;
$color-background-dark: #a4859e;
$color-background-edit: rgb(220, 220, 220);
$color-background-hover: rgb(240, 240, 240);
$color-mat-form-field: rgba(0, 0, 0, 0.04);
$color-mat-table-border: rgba(0, 0, 0, 0.12);
$background-grey: #fbfcfc;
$background-greyY: #ebf8f9;
$background-greyB: #f1feff;
$color-warn: #fbfcfc;

$color-background-vote-form: rgba(233, 233, 233, 0.8);
$color-background-vote-form-light: rgba(233, 233, 233, 0.8);
$subtitle-grey: #805477;
$subtitle-light: #c8b6c5;
$color-field-underline: #949494;

$color-icon: rgba(0, 0, 0, 0.54);
$color-less-emphasize: #757475;
$color-link: #5e7285;
$color-green: #68b946;
$color-red: #c83f3c;
$color-green-light: #91d176;
$color-green-background: rgba(0, 128, 0, 0.7);
$big-screen-field-width: 500px;
$small-screen-field-width: 220px;

$context-bar-height: 48px;
$tab-group-height: 48px;

$big-screen-absolutue-max-width: 2000px;

$big-screen-outer-nav-bar-max-width: 1440px;
$big-screen-inner-nav-bar-max-width: 1280px;

$page-big-max-width: 1280px;
$page-max-width: 960px;
$page-small-max-width: 600px;

$page-very-small-max-width: 480px;
$small-screen-min-width: 240px; // to avoid that navbar is wrapping, should not be used anywhere else

$font-size-big-screens: 14px;
$font-size-small-screens: 16px;
$edit-options-date-width-small: 74px;
$edit-options-date-width-big: 120px;
$edit-options-third-column-width: 100px;
$scrollable-row-height: 56px;
$vote-field-margin-left: 0px;

@mixin vote-indent-margin {
    @include big-screen {
        margin-left: 48px;
    }
    @include small-screen {
        margin-left: 20px;
    }
}
@mixin number-answer-field-width {
    @include big-screen {
        width: 96px;
        margin-right: 24px;
    }
    @include small-screen {
        width: 64px;
        margin-right: 12px;
    }
}
